import { useState, createContext, useMemo} from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children, subMenu, categoryPage, footer, endpoint, siteConfig }) => {


  
  const [categoryPageData, setCategoryPageData] = useState(categoryPage);
  const [subMenuData, setSubMenuData] = useState(subMenu);
  const [footerData, setfooterData] = useState(footer);
  const [endpointData, setEndpoint] = useState(endpoint);
  const [siteConfigData, setSiteConfig] = useState(siteConfig);
 

  const value = useMemo(() => 
  ({ 
    subMenuData, setSubMenuData, 
    categoryPageData, setCategoryPageData, 
    footerData, setfooterData,
    endpointData, setEndpoint,
    siteConfigData, setSiteConfig
  }), 
  [ subMenuData, categoryPageData, footerData, endpointData, siteConfigData]);
 
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
