import { gql } from "graphql-request";
export const PAGE_FIELDS = gql`
    fragment PageFields on Page {
        topics {
          name
          faqs {
            answer
            question
          }
        }
        content {
        json
        references(first: 100) {
          ... on Post {
            id
            slug
            # Commented out category as it fails on flower delivery project
            # category
          }
          ... on Page {
            id
            url 
          }
          ... on Promotion {
            ...PromotionFields
          }
          ... on Asset {
            id
            url(
              transformation: {
                image: { resize: { width: 700, fit: clip } }
                document: { output: { format: webp } }
                validateOptions: true
              }
            )
            ...AssetFields
          }
          ... on DeepLink {
            id
            name
            cta
            affiliate_link
          }
          # ... on ProductReview {
          #   ...ProductReviewFields
          # }
          ... on Faq {
            id
            answer
            question
          }
          ... on Chart {
            id
            name
            promotions {
              ...PromotionFields
            }
          }
          ... on Topic {
            id
            name
            faqs {
              answer
              question
            }
          }
        }
      }
      slug
      headerOneText
      headerTwoText
      title
      metaDescription
      sitemap
      useHeroImage
      headerImage {
        ...AssetFields
      }
      url
      defaultChart {
        name
        promotions(orderBy:score_DESC) {
          ...PromotionFields
        }
      }
    }
`;